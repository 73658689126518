import React from 'react'

import PageTitle from '../../components/PageTitle'
import ImageGallery from '../../components/ImageGallery'
import Wrapper from '../../components/Wrapper'

export default () => (
  <Wrapper>
    <PageTitle name="2017 Conference Photos" keywords="" />
    <ImageGallery />
  </Wrapper>
)
