import React from 'react'
import 'babel-polyfill'
import ImageGallery from 'react-image-gallery'

import './image-gallery.css'

class Gallery extends React.Component {
  handleImageLoad(event) {
    console.log('Image loaded ', event.target)
  }

  render() {
    const images = [
      {
        original: '/carousel/1.jpg',
        thumbnail: '/carousel/1_tn.jpg',
      },
      {
        original: '/carousel/2.jpg',
        thumbnail: '/carousel/2_tn.jpg',
      },
      {
        original: '/carousel/3.jpg',
        thumbnail: '/carousel/3_tn.jpg',
      },
      {
        original: '/carousel/4.jpg',
        thumbnail: '/carousel/4_tn.jpg',
      },
      {
        original: '/carousel/5.jpg',
        thumbnail: '/carousel/5_tn.jpg',
      },
      {
        original: '/carousel/6.jpg',
        thumbnail: '/carousel/6_tn.jpg',
      },
      {
        original: '/carousel/7.jpg',
        thumbnail: '/carousel/7_tn.jpg',
      },
      {
        original: '/carousel/8.jpg',
        thumbnail: '/carousel/8_tn.jpg',
      },
      {
        original: '/carousel/9.jpg',
        thumbnail: '/carousel/9_tn.jpg',
      },
      {
        original: '/carousel/10.jpg',
        thumbnail: '/carousel/10_tn.jpg',
      },
      {
        original: '/carousel/11.jpg',
        thumbnail: '/carousel/11_tn.jpg',
      },
      {
        original: '/carousel/12.jpg',
        thumbnail: '/carousel/12_tn.jpg',
      },
      {
        original: '/carousel/13.jpg',
        thumbnail: '/carousel/13_tn.jpg',
      },
      {
        original: '/carousel/14.jpg',
        thumbnail: '/carousel/14_tn.jpg',
      },
      {
        original: '/carousel/15.jpg',
        thumbnail: '/carousel/15_tn.jpg',
      },
      {
        original: '/carousel/16.jpg',
        thumbnail: '/carousel/16_tn.jpg',
      },
      {
        original: '/carousel/17.jpg',
        thumbnail: '/carousel/17_tn.jpg',
      },
      {
        original: '/carousel/18.jpg',
        thumbnail: '/carousel/18_tn.jpg',
      },
      {
        original: '/carousel/19.jpg',
        thumbnail: '/carousel/19_tn.jpg',
      },
      {
        original: '/carousel/20.jpg',
        thumbnail: '/carousel/20_tn.jpg',
      },
      {
        original: '/carousel/21.jpg',
        thumbnail: '/carousel/21_tn.jpg',
      },
      {
        original: '/carousel/22.jpg',
        thumbnail: '/carousel/22_tn.jpg',
      },
      {
        original: '/carousel/23.jpg',
        thumbnail: '/carousel/23_tn.jpg',
      },
      {
        original: '/carousel/24.jpg',
        thumbnail: '/carousel/24_tn.jpg',
      },
      {
        original: '/carousel/25.jpg',
        thumbnail: '/carousel/25_tn.jpg',
      },
      {
        original: '/carousel/26.jpg',
        thumbnail: '/carousel/26_tn.jpg',
      },
      {
        original: '/carousel/27.jpg',
        thumbnail: '/carousel/27_tn.jpg',
      },
      {
        original: '/carousel/28.jpg',
        thumbnail: '/carousel/29_tn.jpg',
      },
    ]

    return (
      <ImageGallery
        ref={i => (this._imageGallery = i)}
        items={images}
        slideInterval={2000}
        onImageLoad={this.handleImageLoad}
        lazyLoad
        autoPlay
      />
    )
  }
}
export default Gallery
